import React from 'react'

import { Image, Text } from 'theme-ui'
import redirectToPage from '/lib/redirect'

import Box from '/components/Commons/Box'

import styles from '/components/Korea/HomeCard/styles'
import rightAlignedStyles from './styles'
import { useRouter } from 'next/router'

const RightAligned = ({ ...props }) => {
  const { entry, renderlabel, isMobile, locale } = props
  const router = useRouter()
  return (
    <Box
      sx={{
        ...styles.wrapperContent(false, isMobile),
      }}
    >
      <Box sx={styles.infoContainer}>
        <Box sx={styles.labelPadding}>{renderlabel(entry)}</Box>
        <Box sx={rightAlignedStyles.container}>
          <Box sx={rightAlignedStyles.imageWrapper}>
            <Image
              src={entry.images[0]?.image}
              onClick={() => redirectToPage(router, locale, entry.cta?.action)}
            />
          </Box>
          <Box sx={rightAlignedStyles.descriptionContainer}>
            <Text sx={styles.contentDescription}>{entry.description}</Text>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default RightAligned
