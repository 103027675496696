export default {
  mainContainer: {
    maxWidth: ['', '896px'],
    margin: '0 auto',
  },
  iframe: {
    width: '100vw',
    height: ['600px', '570px', '570px'],
    border: 'none',
    overflow: 'hidden',
    margin: '0',
  },
  spinnerContainer: {
    width: '100vw',
    height: ['630px', '600px', '570px'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'tmBlue',
    svg: { color: 'white' },
  },
  mobileFirstChild: {
    '& > div:first-child': {
      pt: '40px',
    },
    '& > div:last-child': {
      pb: '0px',
    },
  },
}
