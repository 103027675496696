export default {
  contentsBox: {
    width: ['auto', '100%'],
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: ['flex-start', 'center'],
    gap: '2%',
    rowGap: '10px',
    padding: '0',
    paddingBottom: [0, '30px'],
    position: ['relative', 'absolute'],
    left: 0,
    top: ['0', '88%'],
    zIndex: [1, 2],
  },
}
