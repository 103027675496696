import { useRouter } from 'next/router'
import React from 'react'

import { Image } from 'theme-ui'

import redirectToPage from '/lib/redirect'

import Box from '/components/Commons/Box'

import styles from '/components/Korea/HomeCard/styles'
import fullWidthStyles from './styles'

const FullWidth = ({ ...props }) => {
  const { entry, renderlabel, isMobile, locale } = props
  const router = useRouter()

  return (
    <React.Fragment>
      <Box sx={fullWidthStyles.imageContainer(entry.text)}>
        <Image
          sx={fullWidthStyles.image}
          onClick={() =>
            redirectToPage(router, locale, entry.images[0].cta?.action)
          }
          src={entry.images[0]?.image}
        />
      </Box>
      {isMobile && (
        <Box
          sx={{
            ...styles.wrapperContent(false, isMobile),
            ...fullWidthStyles.labelContainer(isMobile),
          }}
        >
          <Box sx={styles.infoContainer}>{renderlabel(entry)}</Box>
        </Box>
      )}
    </React.Fragment>
  )
}

export default FullWidth
