export default {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  elementContainer: {
    width: '50%',
    '&:first-child': {
      mr: 3,
    },
  },
  description: {
    p: ['0 20px', ''],
    fontSize: ['16px', '17px'],
    fontFamily: 'spoqaRegular',
    fontWeight: '400',
    lineHeight: ['19px', '23px'],
    color: 'tmBlue',
    pt: 0,
  },
}
