export default {
  container: (hideTitle, hideImage) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    p: ['0 20px', '0 20px', '0 0'],
    mt: !hideTitle && !hideImage ? '116px' : '97px',
    mb: '27px',
    cursor: 'pointer',
  }),

  imageContainer: {
    cursor: 'pointer',
    maxWidth: '515px',
    width: ['100%', '100%', '100%'],
    height: '315px',
    mr: 3,
  },

  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    aspectRatio: '1 / 0',
    borderRadius: ['0px', '8px'],
  },

  title: {
    fontSize: '35px',
    fontWeight: 'bold',
    display: 'inline-block',
    p: {
      color: 'tmRed',
      display: 'inline-block',
      my: 0,
    },
    span: {
      color: 'tmBlue',
      display: 'inline-block',
      my: 0,
    },
  },

  subTitle: {
    display: 'block',
    fontWeight: 400,
    color: 'tmBlue',
    fontSize: '24px',
    fontFamily: 'heading',
    mb: 3,
    wordBreak: 'break-word',
  },

  description: {
    display: 'block',
    whiteSpace: 'pre-line',
    fontWeight: '400',
    maxWidth: ['25ch', '25ch', ''],
    fontSize: ['18px', '24px'],
    color: 'tmBlue',
    wordBreak: 'break-word',
  },
}
