export default {
  container: {
    position: 'relative',
    minHeight: '100vh',
    px: ['30px', '0px'],
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pb: '2.5rem',
    position: ['static', 'relative'],
  },
  pageWrapper: {
    py: ['40px', '0px'],
  },
}
