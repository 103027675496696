export default {
  videoWrapper: (isMobile) => ({
    width: isMobile ? '100%' : '440px',
    height: isMobile ? '100%' : '288px',
    borderRadius: '8px',
    position: 'relative',
  }),

  reactPlayer: (isMobile) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: '8px',
    maxWidth: isMobile ? '100%' : '440px',
    maxHeight: isMobile ? '100%' : '288px',
    overflow: 'hidden',
  }),
}
