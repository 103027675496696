/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'

import { jsx, Box, Image, Heading, Text, Button } from 'theme-ui'

import redirectAuth from '/lib/redirectAuth'
import SwiperCore, { Virtual, Pagination, EffectFade } from 'swiper'

import 'swiper/css'
import 'swiper/css/virtual'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import styles from './styles'
import Carousel from './Carousel'
import FullWidth from './FullWidth'
import RightAligned from './RightAligned'

SwiperCore.use([Pagination, EffectFade, Virtual])

const HomeCard = React.forwardRef(
  ({ onClickContent, isMobile, titleAligned, ...props }, ref) => {
    const router = useRouter()
    const locale = props.locale
    const entry = props

    const redirect = (router, locale, action, session, loggedRoutes) => {
      if (/^(http[s]{0,1}:\/\/)/.test(action))
        window.open(action, '_blank').focus()
      else redirectAuth(router, locale, action, session, loggedRoutes)
    }
    const { data: session } = useSession()
    const isCoachingCorner = entry.cta?.action === '/coachingcorner'

    const loggedRoutes = ['/coachingcorner']

    const isSecondaryButton = isCoachingCorner && isMobile

    const subEntries = entry.containsSubImages
      ? entry.images[0]?.entries.filter((e) => !e.hide)
      : []

    const renderlabel = (entry, index, aia) => {
      if (entry.cta?.label) {
        return (
          <Button
            sx={
              isSecondaryButton
                ? styles.secondaryButton
                : {
                    ...styles.contentButton(index, aia, isMobile),
                    backgroundColor: entry.backgroundColor
                      ? entry.backgroundColor
                      : 'transparent',
                    color: entry.textColor,
                    justifyContent: entry.homePageProperty
                      ?.split(',')
                      .includes('fullWidth')
                      ? 'flex-end'
                      : 'flex-start',
                  }
            }
            onClick={() =>
              redirect(router, locale, entry.cta?.action, session, loggedRoutes)
            }
          >
            <Box sx={styles.labelArrow}>
              <p>{entry.cta?.label}</p>
              <Box sx={styles.arrow}></Box>
            </Box>
          </Button>
        )
      }
    }

    const renderContent = (entry, isSubEntry, index, aia, twoEntries) => {
      const displayDescription =
        !entry.specialTitle &&
        entry.description &&
        !entry.isCarousel &&
        isSubEntry &&
        !isMobile
      return (
        <React.Fragment>
          {entry.homePageProperty?.split(',').includes('fullWidth') ? (
            <FullWidth
              key={index}
              isMobile={isMobile}
              locale={locale}
              renderlabel={renderlabel}
              entry={entry}
            />
          ) : entry.homePageProperty
              ?.split(',')
              .includes('textAlignedRight') ? (
            <RightAligned
              key={index}
              isMobile={isMobile}
              locale={locale}
              renderlabel={renderlabel}
              entry={entry}
            />
          ) : entry.isCarousel ? (
            <Carousel
              key={index}
              index={index}
              isSubEntry={isSubEntry}
              isMobile={isMobile}
              locale={locale}
              renderlabel={renderlabel}
              isSecondaryButton={isSecondaryButton}
              isCoachingCorner={isCoachingCorner}
              entry={entry}
            />
          ) : (
            <Box
              sx={
                isSubEntry
                  ? {
                      ...styles.wrapperContent(isSubEntry, isMobile),
                      ...styles.wrapperSubEntryDesktop(twoEntries),
                    }
                  : {
                      ...styles.wrapperContent(isSubEntry, isMobile),
                    }
              }
            >
              <Box sx={styles.infoContainer}>
                {entry.cta?.label && (
                  <React.Fragment>
                    {renderlabel(entry, index, aia)}
                    <Box sx={styles.imageContainer}>
                      {entry?.images && (
                        <Image
                          src={entry.images[0].image}
                          onClick={() =>
                            redirect(
                              router,
                              locale,
                              entry.images[0].cta?.action,
                              session,
                              loggedRoutes,
                            )
                          }
                        />
                      )}
                    </Box>
                  </React.Fragment>
                )}
                {!entry.cta?.label && entry.images?.length <= 1 && (
                  <Box sx={styles.imageContainer}>
                    {entry?.images && (
                      <Image
                        src={entry.images[0]?.image}
                        onClick={() =>
                          redirect(
                            router,
                            locale,
                            entry.images[0].cta?.action,
                            session,
                            loggedRoutes,
                          )
                        }
                      />
                    )}
                  </Box>
                )}
                {displayDescription && (
                  <Text sx={styles.contentDescription}>
                    {entry.description}
                  </Text>
                )}
              </Box>
            </Box>
          )}
        </React.Fragment>
      )
    }

    return (
      <Box
        ref={ref}
        sx={{
          ...styles.container,
          ...styles.paddingY(entry),
          backgroundColor: entry.backgroundColor,
          color: entry.textColor,
          position: isCoachingCorner && 'relative',
        }}
        onClick={() => onClickContent()}
      >
        <Box sx={styles.wrapperContent(false, isMobile)}>
          <Box sx={styles.infoContainer}>
            {entry.text && (
              <Heading
                as="h4"
                sx={{ ...styles.contentTitle, variant: `text.contentTitle` }}
                onClick={() =>
                  redirect(
                    router,
                    locale,
                    entry.cta?.action,
                    session,
                    loggedRoutes,
                  )
                }
              >
                <Box
                  sx={{
                    ...styles.title(entry),
                    ...styles.titlePadding(isMobile, titleAligned),
                    ...styles.titleAligned(titleAligned),
                    ...styles.titleSubImages(entry.containsSubImages),
                  }}
                >
                  <p>{entry.text}</p> <span>{entry.subTitle}</span>{' '}
                  {entry.homePageProperty?.split(',').includes('arrow') && (
                    <Box sx={styles.arrow}></Box>
                  )}
                </Box>
                {/* TODO: Fix this <h5> cannot appear as a child of <h4></h4> */}
                <Box as={'h5'} sx={styles.subTitle}>
                  {entry.subtext}
                </Box>
              </Heading>
            )}
          </Box>
        </Box>
        {/* // * Media Center and AIA sections, the ones that contains multiple subsections */}
        {entry.containsSubImages ? (
          <React.Fragment>
            {/* // Render the carrousel */}
            {entry.images[0]?.entries.some((e) => e.isCarousel) ? (
              <React.Fragment>
                {subEntries.map((e, index) => renderContent(e, true, index))}
              </React.Fragment>
            ) : (
              <Box sx={styles.multipleRowImages}>
                {subEntries.map((e, index) =>
                  renderContent(e, true, index, true, subEntries.length === 2),
                )}
              </Box>
            )}
          </React.Fragment>
        ) : (
          renderContent(entry, false)
        )}
      </Box>
    )
  },
)

export default HomeCard
