import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import ReactModal from 'react-modal'
import Box from '/components/Commons/Box'
import styles from './styles'

const Modal = ({ children, isOpen, onClose, customStyles }) => {
  const modalStyles = { ...styles, ...customStyles }
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStyles.modal}
      shouldCloseOnOverlayClick={true}
    >
      <Box sx={modalStyles.closeIcon}>
        <FontAwesomeIcon
          icon={faClose}
          sx={{
            variant: 'icons.menuClose',
            color: 'white',
          }}
          size="2x"
          onClick={onClose}
        />
      </Box>
      {children}
    </ReactModal>
  )
}

export default Modal
