export default {
  modal: {
    img: {
      width: '100%',
      objectFit: 'cover',
      aspectRatio: '1 / 0',
    },
    overlay: {
      backgroundColor: 'rgba(15, 32, 75, 0.9)',
      zIndex: 1011,
    },
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      background: 'transparent',
      padding: '40px',
      width: '100%',
    },
  },
  closeIcon: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    color: 'white',
  },
}
