/* eslint-disable react/no-unknown-property */
/** @jsxRuntime classic */
/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import Modal from '/components/Commons/Modal'
import { useModalDispatch, useModalState } from '/contexts/modal'
import utils from '/utils'

import { Footer, Header } from '/components/index'
import styles from './styles'
import NewKoreaDefaultLayout from '/components/Korea/v3/NewKoreaDefaultLayout'

const WithMainLayout = (Page) =>
  function Component(props) {
    const { layout, sideMenu, languageSwitch, locale } = props?.pageProps ?? {}

    const isMobile = utils.isMobile()
    const modalState = useModalState()
    const modalDispatch = useModalDispatch()

    const pageProps = { ...props, isMobile }

    if (locale === 'kr') return NewKoreaDefaultLayout(Page)(props)

    return (
      // eslint-disable-next-line react/no-unknown-property
      <main sx={styles.container}>
        <Box sx={styles.content}>
          <Header
            carrousel={layout?.carrousel}
            sideMenu={sideMenu}
            languageSwitch={languageSwitch}
            isMobile={isMobile}
          />
          <Box sx={styles.pageWrapper}>
            <Page {...pageProps} />
          </Box>
          <Modal
            show={modalState !== null}
            onClose={() => {
              modalState?.onClose()
              modalDispatch({ type: 'hide' })
            }}
          >
            {modalState?.component}
          </Modal>
        </Box>
        {layout?.footer && <Footer {...layout?.footer} />}
        <div data-iframe-height="1"></div>
      </main>
    )
  }

export default WithMainLayout
