export default {
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: ['100%', '100%'],
  },
  descriptionContainer: {
    ml: ['10px', 3],
    width: ['45%', ''],
    fontFamily: 'spoqaRegular',
  },
  imageWrapper: {
    minWidth: ['200px', '440px'],
    width: ['55%', '440px'],
    overflow: 'hidden',
    height: ['', '280px'],
    borderRadius: '8px',
    img: {
      borderRadius: '8px',
      aspectRatio: ['1 / 1', '11 / 7'],
    },
  },
}
