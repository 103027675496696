export default {
  container: {
    backgroundColor: 'black',
    width: '100%',
    height: 'auto',
    borderRadius: '10px 10px 10px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    gap: '10px',
  },

  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
  },

  contentTitle: {
    fontSize: '2.3rem',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: '400',
    lineHeight: '25px',
    span: { display: 'block' },
  },

  contentButton: {
    width: '50%',
    padding: '5px',
    backgroundColor: 'tmWhite',
    color: 'tmRed',
    border: 'none',
    borderRadius: '5px 5px 5px 0px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  boxWidth: (mobileCardsPerRow) => ({
    width: [`${100 / mobileCardsPerRow - 1}%`, '180px'],
  }),
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 0.8,
    borderRadius: '10px 10px 10px 0px',
  },
  description: {
    zIndex: 1,
    backgroundColor: 'transparent !important',
    color: '#FFF',
    textAlign: 'center',
    lineHeight: '8px',
    fontWeight: 'bold',
  },

  icon: {
    position: 'relative',
    width: '56px',
  },
}
