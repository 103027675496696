import React, { useState } from 'react'
import { useSession } from 'next-auth/react'

import { Swiper, SwiperSlide } from 'swiper/react'
import ReactPlayer from 'react-player/lazy'

import { Image, Text, Button } from 'theme-ui'
import carouselStyles from './styles'
import styles from '/components/Korea/HomeCard/styles'

import redirectToPage from '/lib/redirect'

import 'swiper/css'
import 'swiper/css/virtual'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import Box from '/components/Commons/Box'
import { useRouter } from 'next/router'
import Modal from './Modal'

const Carousel = ({ ...props }) => {
  const router = useRouter()
  const { entry, renderlabel, locale, isMobile, isSubEntry, index } = props
  const { data: session } = useSession()

  const [indexOpen, setIndexOpen] = useState()

  return (
    <Box sx={styles.wrapperCarrouselContent}>
      <Box sx={styles.infoCarrouselContainer}>
        {renderlabel(entry, index)}
        {entry.description && !isMobile && (
          <Text
            sx={{
              ...styles.contentDescription,
              pt: 0,
              pb: 1,
              fontWeight: '400',
            }}
          >
            {entry.description}
          </Text>
        )}
        <Box
          sx={{
            ...styles.sliderWrap(
              entry.homePageProperty?.split(',').includes('bigCarousel'),
              isSubEntry,
            ),
          }}
        >
          <Swiper
            slidesPerView="auto"
            spaceBetween={isMobile ? 20 : 16}
            pagination={{ enabled: entry?.images.length > 1, clickable: true }}
          >
            {entry?.images &&
              entry?.images.map((slide, index) => (
                <SwiperSlide key={`${index}-carrousel`} virtualIndex={index}>
                  {entry.openInPopup ? (
                    <>
                      {slide.video || slide.embedUrl ? (
                        <Box sx={carouselStyles.videoWrapper(isMobile)}>
                          <ReactPlayer
                            playsinline
                            playing
                            url={slide.video || slide.embedUrl}
                            controls={true}
                            style={carouselStyles.reactPlayer(isMobile)}
                            light={slide.thumbnail}
                            width={'100%'}
                            height={'100%'}
                            onClick={() =>
                              entry.loginRequired &&
                              session !== null &&
                              redirectToPage(router, locale, '/login')
                            }
                            onContextMenu={(e) => e.preventDefault()}
                            config={{
                              file: {
                                attributes: { controlsList: 'nodownload' },
                                forceAudio: true,
                                forceVideo: true,
                              },
                            }}
                          />
                        </Box>
                      ) : (
                        <React.Fragment>
                          {indexOpen === index ? (
                            <Modal isOpen={true} onClose={() => setIndexOpen()}>
                              <Box>
                                <Image
                                  sx={styles.modalImage}
                                  src={slide.image}
                                />
                              </Box>
                            </Modal>
                          ) : (
                            <Image
                              sx={{
                                ...styles.carouselImage(
                                  entry.homePageProperty
                                    ?.split(',')
                                    .includes('bigCarousel'),
                                ),
                              }}
                              src={slide.video ? slide.thumbnail : slide.image}
                              onClick={() => setIndexOpen(index)}
                            />
                          )}
                        </React.Fragment>
                      )}
                    </>
                  ) : (
                    <>
                      <Button
                        sx={styles.imageWrapper}
                        onClick={() =>
                          redirectToPage(router, locale, slide.cta?.action)
                        }
                      >
                        <Box sx={styles.overlay} />
                        <Text sx={styles.carrouselDescription}>
                          {slide.text}
                        </Text>
                        <Image
                          sx={{
                            ...styles.carouselImage(
                              entry.homePageProperty
                                ?.split(',')
                                .includes('bigCarousel'),
                            ),
                          }}
                          src={slide.video ? slide.thumbnail : slide.image}
                        />
                      </Button>
                    </>
                  )}
                </SwiperSlide>
              ))}
          </Swiper>
        </Box>
      </Box>
    </Box>
  )
}

export default Carousel
